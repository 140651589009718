import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import founder1 from "../../../assets/images/appImages/founder_1.png";
import founder2 from "../../../assets/images/appImages/founder_2.png";
import {
  AiOutlineGoogle,
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillLinkedin,
} from "react-icons/ai";
import { TeamData } from "./data";
import { Link } from "react-router-dom";
import FooterPage from "../../auth/FooterPage";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img loading="lazy" src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
      <div className="social-networks d-flex align-items-center justify-content-center gap-2">
        <Link target="_blank" to={`#`}>
          <AiOutlineGoogle />
        </Link>
        <Link target="_blank" to={`${twitter}`}>
          <AiOutlineTwitter />
        </Link>
        <Link target="_blank" to={`${facebook}`}>
          <AiFillFacebook />
        </Link>
        <Link target="_blank" to={`${linkedin}`}>
          <AiFillLinkedin />
        </Link>
      </div>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">Tayeur Market</h3>
                <div className="custom-content">
                  <strong>Tayeur Market</strong>, est une boutique en ligne visant à améliorer la production locale de chaussures.
                  Elle permet aussi aux cordonniers de mieux vendre leurs produits dans les différentes localités du Sénégal et vous
                  aider dans vos achats pour les événements et les cérémonies. <br />
                  Elle a pour objectif de promouvoir le « made in Sénégal » en présentant des chaussures locales de qualité collante
                  avec les dernières tendances. Il permettra non seulement aux hommes et aux femmes de porter des chaussures locales
                  élégantes, simples et magnifiques, mais à des prix abordables.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-0 who-we-are">
          <div className="container">
            <div className="pb-2 our-mission custom-content">
              <strong>Mission :</strong> Vendre des chaussures à travers le monde, promouvoir le Made in Sénégal dans le monde entier
              et vendre tout ce que les artisans sénégalais produisent dans le monde entier
            </div>
            <div className="pb-2 our-vision custom-content">
              <strong>Vision :</strong> Avoir un continent africain où les artisans africains produisent des produits de qualité, à prix
              accessible et les écoulent dans le continent et dans le monde entier tout en satisfaisant leurs clients.
            </div>
            <div className="our-value custom-content">
              <strong>Valeur :</strong> Fiabilité, Qualité, Flexibilité et Tranquillité
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '4rem 0' }}></div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
