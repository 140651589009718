import React from "react";
import { NavLink } from "react-router-dom";
import Banner from "../../assets/images/appImages/img-banner.png";
import Deco from "../../assets/images/appImages/deco.png";

function BannerHomepage() {
  return (
    <section className="section-banner-homepage">
      <div className="row align-items-center">
        <div className="col-md-7 col-left-banner-accueil mb-3">
          <div className="content-col-left-banner-accueil">
            <h2 className="title-home-page">
              <span>Chaussure</span>
            </h2>
            <h2 className="title-home-page">
              <span>locale</span>
            </h2>
            <div className="content-text-home-page-banner mt-3 mb-5">
              <p className="text-home-page-banner">
                Plus besoin de vous déplacer, commandez en ligne
              </p>
            </div>
            <div className="container-btn-banner-accueil">
              <NavLink className="btn btn-title-home-page" to="/produits">
                Voir nos produits
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-right-banner-accueil mb-3">
          <div className="content-col-right-banner-accueil">
            {/*  */}
            <div className="container-img-deco-banner">
              <img
                loading="lazy"
                src={Deco}
                alt="Deco"
                className="img-deco-banner"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerHomepage;
